import { useCheckout } from '@components/_checkout/CheckoutProvider'
import { Field } from '@components/gassan-ui'
import { en, nl } from '@lib/countries'
import { useFormikContext } from 'formik'
import { useTranslation } from 'next-i18next'
import React from 'react'

type Props = {
  label: string
  name: string
}

const CountrySelect: React.FC<Props> = ({ label, name }) => {
  const {
    i18n: { language },
  } = useTranslation()
  const options = language === 'nl' ? nl : en
  const formik = useFormikContext()
  const checkout = useCheckout()
  return (
    <Field
      field="select"
      name={name}
      label={label}
      gridColumn="span 12"
      onChange={(e) => {
        formik.setFieldValue(name, e.target.value)
        checkout.setCountry(e.target.value)
      }}
    >
      <option value="">{label}</option>
      {options.map((option) => (
        <option value={option.code} key={option.code}>
          {option.name}
        </option>
      ))}
    </Field>
  )
}

export default CountrySelect
